import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { LanguageService } from '@services/internal/language.service';

export class AppMissingTranslationHandler implements MissingTranslationHandler {
  handle = (params: MissingTranslationHandlerParams) => {
    /** Since we have strict typed translations, that are checking on build, we do not need this error reporter.
     * But we might need it in the future, if we use another approach to load translations json file */
    setTimeout(() => {
      if (!Object.values(LanguageService.service?.store?.translations).length) {
        console.log(`%c Missing translation ${params.key}`, 'background: #bada55; color: red');

        // GlobalErrorHandlerService.missingTranslations(`Missing translation ${params.key}`);
      }
    }, 1000);
    return '';
  };
}
