import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MaintenanceService } from '@services/maintenance.service';

@Injectable({ providedIn: 'root' })
export class MaintenanceGuard {
  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    if (this.maintenanceService.isMaintenance$.value) {
      this.router.navigate(['/maintenance']);
      return false;
    } else {
      return true;
    }
  }
}
