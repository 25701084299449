import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, SharedModule, ServiceWorkerModule.register('ngsw-worker.js')],
  bootstrap: [AppComponent]
})
export class AppModule {}
