import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { HandleRequestsService } from '@services/offline/handle-requests.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineInterceptor implements HttpInterceptor {
  constructor(private offlineStatusService: OfflineStatusService, private handleRequestsService: HandleRequestsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.offlineStatusService.areRequestsOffline$.value) {
      return from(this.handleRequestsService.getOfflineData(request));
    }
    return next.handle(request);
  }
}
