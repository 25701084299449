import { ErrorHandler, NgModule } from '@angular/core';
import { CoreRoutingModule } from './core-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthGuard } from '@guards/auth/auth.guard';
import { AutoSaveGuard } from '@guards/autosave/auto-save.guard';
import { PermissionsGuard } from '@guards/permissions/permissions.guard';
import { GlobalErrorHandlerService } from '@services/internal/global-error-handler.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreTranslationModule } from './core-translation.module';
import { OfflineInterceptor } from './interceptors/offline.interceptor';
import { MaintenanceGuard } from '@guards/maintenance/maintenance.guard';

@NgModule({
  imports: [CoreRoutingModule, BrowserAnimationsModule, CoreTranslationModule.forRoot()],
  providers: [
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: OfflineInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthInterceptor },
    AuthGuard,
    AutoSaveGuard,
    MaintenanceGuard,
    PermissionsGuard,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
})
export class CoreModule {}
