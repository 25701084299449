import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppMissingTranslationHandler } from '@shared/utils/i18n/i18n-utils';

// AoT requires an exported function for factories
// If in the future the file will be large,it's possible to separate json file and load it separately using
// https://github.com/denniske/ngx-translate-multi-http-loader
// https://github.com/larscom/ngx-translate-module-loader
const day = new Date().getDay();
export const HttpLoaderFactory = (httpClient: HttpClient) => new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?' + day);

export const TranslateConfig = {
  defaultLanguage: 'en',
  languages: ['en', 'es', 'de', 'fr', 'vi'],
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: AppMissingTranslationHandler
  }
};

@NgModule({
  providers: [TranslateService]
})
export class CoreTranslationModule {
  static forRoot(): ModuleWithProviders<TranslateModule> {
    return TranslateModule.forRoot(TranslateConfig);
  }

  static forChild(): ModuleWithProviders<TranslateModule> {
    return TranslateModule.forChild(TranslateConfig);
  }
}
