<div class="container" fxLayout="row" fxLayoutAlign="center center">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="48px"
    fxLayoutGap.xs="0px"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="start stretch">
      <span class="offlineTitle">{{ 'shared.components.offline' | language }}</span>
      <span class="oopsTitle">{{ 'shared.components.offlineCaption' | language }}</span>
    </div>
    <span class="connectSxIcon" fxLayoutAlign="center center"></span>
  </div>
</div>
